import "./App.module.scss";
import Header from "./components/header/Header";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Platform from "./pages/Platform";
import Footer from "./components/footer/Footer";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import HowItWorks from "./pages/HowItWorks";
import OurStory from "./pages/OurStory";
import Services from "./pages/Services";
import PrivacyPopup from "./components/term-popup/PrivacyPopup";
import TermsPopup from "./components/term-popup/TermsPopup";
import { useLayoutEffect } from "react";

const Wrapper = ({ children }) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    document.getElementsByClassName("App")[0].scrollTo(0, 0);
  }, [pathname]);
  return children;
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Wrapper>
          <Header />
          <Routes>
            <Route path="" element={<Home />}></Route>
            <Route path="/platform" element={<Platform />}></Route>
            <Route path="/pricing" element={<Pricing />}></Route>
            <Route path="/services" element={<Services />}></Route>
            <Route path="/our-story" element={<OurStory />}></Route>
            <Route path="/how-it-works" element={<HowItWorks />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/privacy" element={<PrivacyPopup />}></Route>
            <Route path="/terms" element={<TermsPopup />}></Route>
          </Routes>
          <Footer />
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
