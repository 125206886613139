import Easy from "../components/easy/Easy";
import Feedback from "../components/feedback/Feedback";
import Landing from "../components/landing/Landing";
import TheAdvantage from "../components/the-advantage/TheAdvantage";
import YourCall from "../components/your-call/YourCall";
import YourWay from "../components/your-way/YourWay";

const Home = () => {
  return (
    <>
      <Landing />
      <Easy />
      <TheAdvantage />
      <YourWay />
      <YourCall />
      <Feedback />
    </>
  );
};

export default Home;
