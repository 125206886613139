import styles from "./Landing.module.scss";

import tag from "../../../assets/imgs/sale-tag.png";
import phonebook from "../../../assets/imgs/phonebook.png";
import selfPrint from "../../../assets/imgs/self-print-white-small.png";
import genviteLabels from "../../../assets/imgs/genvite-labels-white-small.png";
import envelopePrint from "../../../assets/imgs/print-on-envelope-white-small.png";
import voiceCalls from "../../../assets/imgs/voice-calls-white-small.png";
import textMassage from "../../../assets/imgs/text-messages-white-small.png";
import massEmail from "../../../assets/imgs/mass-emails-white-small.png";
import pricingLandingImg from "../../../assets/imgs/pricing-landing.png";


const PricingLanding = () => {
  return (
    <div className={styles.landing}>
      <div className={`df aic content-wrpr ${styles.landingSection}`}>
        <div className={styles.imgWrpr}>
          <img src={pricingLandingImg} alt="" />
        </div>
        <div className={styles.textWrpr}>
          <h5 className={`ffi fw500 fs18 c084571`}>
            OUR PRICING LIST WAS DESIGNED WITH YOU IN MIND
          </h5>

          <h2 className={`ffm fw700 fs32 c04395F`}>
            BUILDING YOUR LIST IS FREE:
          </h2>

          <p className={`ffm fw600 fs22 c4377B3 ${styles.firstP}`}>
            Select generously. You will have the opportunity to choose invitees
            from your list at checkout.
          </p>

          <p className={`ffm fw600 fs22 c084571 ${styles.secondP}`}>
            YOUR CONTACT LIST IS & REMAINS 100% FREE
          </p>

          <p className={`ffm fw500 fs18 c084571 ${styles.thirdP}`}>
            Create an account and enjoy the usage of all our tools, including
            contact suggestions, filters, search tools, maps and more — all at
            zero charge
          </p>
        </div>
        {/* <h2 className={`ffi fw500 fs32 c084571`}>
          PRICING LIST WAS DESIGNED WITH YOU IN MIND
        </h2>

        <p
          className={`df acc bc084571 cfff ffm fw600 fs28 ${styles.subHeader}`}
        >
          BUILDING YOUR LIST IS FREE:
        </p>

        <p className={`ffm fw500 fs20 c084571 tac ${styles.createAcc}`}>
          Create an account and enjoy the usage of all our tools, including
          contact suggestions, filters, search tools, maps and more — all at
          zero charge
        </p>

        <h4 className={`ffm fw600 fs28 c084571 ttuc tac`}>
          YOUR CONTACT LIST REMAINS 100% FREE
        </h4>

        <h5 className={`ffm fw600 fs28 c4377B3 tac`}>
          Select generously. You will have the opportunity to choose invitees
          from your list at checkout.
        </h5> */}
      </div>
      <div className={`bc548ED1 ${styles.bar}`}>
        <div className={`content-wrpr cfff ffm fw600 fs26 `}>
          Limited time offer
          <img src={tag} alt="" />
        </div>
      </div>
      <div className={`bcE7EEF8 ${styles.info}`}>
        <div className={`ffm content-wrpr ${styles.contentWrpr}`}>
          <div className={styles.toSec}>
            <p className={`fw600 fs22 c04395F ${styles.a}`}>
              PLATFORM LAUNCH SPECIAL
            </p>
            <p className={`fw700 c04395F fs32 ${styles.b}`}>
              25% DISCOUNT SITE-WIDE
            </p>

            <a
              href="https://portal.genvite.com/auth/signup"
              rel="noopener noreferrer"
              className={`ffm fs18 cfff bc126094`}
            >
              Get Started
            </a>
          </div>
        </div>
      </div>
      <div className={`bcE7EEF8 ${styles.pb}`}>
        <div
          className={`content-wrpr df cfff ffm fw600 fs26 ${styles.contentWrpr}`}
        >
          <div className={`df ffc jcc c084571`}>
            <p className={`ffm fw700 fs24 ttuc`}>Attention:</p>
            <p className={`ffm fw500 fs24`}>
              For those who purchased the Satmar Directory we are now offering
              an additional{" "}
            </p>
            <p className={`ffm fs28 fw700`}>$20 Discount* </p>
            <p className={`ffm fw500 fs24`}>
              when utilizing your Book ID at checkout!
            </p>
            <p className={`ffm fs18 fw500 ${styles.ast}`}>
              *Minimum $50 order. May only be redeemed by book owner.
            </p>
          </div>
          <div className={`mla ${styles.imgWrpr}`}>
            <img src={phonebook} alt="" />
          </div>
        </div>
      </div>
      <div className={`content-wrpr`}>
        <div className={`bc126094 ${styles.banner}`}>
          <p className={`ffm fs28 fw700 tac cD4E6FA`}>
            PRICING FOR GENVITE SERVICES
          </p>
          <p className={`ffm fs24 fw700 tac cfff`}>
            Only pay for what you need, No Minimum required.
          </p>
        </div>
      </div>

      <div className={`bcfff ${styles.priceList}`}>
        <div className={`content-wrpr`}>
          <div className={`bcfff ${styles.priceListInnerWrpr}`}>
            <div className={styles.sec}>
              <div className={`df aic ffm fs18 fw700 cfff ${styles.secHeader}`}>
                <div className={`df acc ${styles.imgWrpr}`}>
                  <img src={selfPrint} alt="" />
                </div>{" "}
                Self-print Labels
              </div>
              <p className={`ffm fw500 fs18 cfff ${styles.expText}`}>
                We offer ready-to-print templates, with a wide choice of fonts:
              </p>
              <div className={styles.priceSec}>
                <p className={`ffm fw600 fs18 c084571`}>Discounted price;</p>
                <p className={`ffm fw500 fs18 c084571`}>$0.30 per contact</p>
              </div>
            </div>
            <div className={styles.sec}>
              <div className={`df aic ffm fs18 fw700 cfff ${styles.secHeader}`}>
                <div className={`df acc ${styles.imgWrpr}`}>
                  <img src={genviteLabels} alt="" />
                </div>
                Genvite Labels
              </div>
              <p className={`ffm fw500 fs18 cfff ${styles.expText}`}>
                Printed in-house by our team, with choice of fonts available:
              </p>
              <div className={styles.priceSec}>
                <p className={`ffm fw600 fs18 c084571`}>
                  Discounted price;{" "}
                  <span className={`ffm fw500 fs18 c084571`}>
                    Regular white labels (30 per sheet) = $0.35
                  </span>
                </p>
                <p className={`ffm fw600 fs18 c084571`}>
                  Discounted price;{" "}
                  <span className={`ffm fw500 fs18 c084571`}>
                    Large clear labels (14 per sheet) = $0.42{" "}
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.sec}>
              <div className={`df aic ffm fs18 fw700 cfff ${styles.secHeader}`}>
                <div className={`df acc ${styles.imgWrpr}`}>
                  <img src={envelopePrint} alt="" />
                </div>{" "}
                Print on Envelope
              </div>
              <p className={`ffm fw500 fs18 cfff ${styles.expText}`}>
                We will print recipient’s address on the envelope, matching the
                font and style of the return address
              </p>
              <div className={styles.priceSec}>
                <p className={`ffm fw600 fs18 c084571`}>
                  Discounted price;{" "}
                  <span className={`ffm fw500 fs18 c084571`}>
                    $0.50 per envelope
                  </span>
                </p>
                <p className={`ffm fw400 fs16 c084571`}>
                  (Complete stuffing & mailing, additional $0.30 + postage)
                </p>
              </div>
            </div>
            <div className={styles.sec}>
              <div className={`df aic ffm fs18 fw700 cfff ${styles.secHeader}`}>
                <div className={`df acc ${styles.imgWrpr}`}>
                  <img src={voiceCalls} alt="" />
                </div>{" "}
                Voice Calls
              </div>
              <p className={`ffm fw500 fs18 cfff ${styles.expText}`}>
                Invite family and loved ones with a phone call
              </p>
              <div className={styles.priceSec}>
                <p className={`ffm fw600 fs18 c084571`}>
                  Discounted price;{" "}
                  <span className={`ffm fw500 fs18 c084571`}>
                    $0.12 per call*
                  </span>
                </p>
                <p className={`ffm fw400 fs16 c084571`}>
                  (*Call duration up to 2 minutes)
                </p>
              </div>
            </div>
            <div className={styles.sec}>
              <div className={`df aic ffm fs18 fw700 cfff ${styles.secHeader}`}>
                <div className={`df acc ${styles.imgWrpr}`}>
                  <img src={textMassage} alt="" />
                </div>{" "}
                Text Messages
              </div>
              <p className={`ffm fw500 fs18 cfff ${styles.expText}`}>
                Send a text to their phone with your simcha invitation
              </p>
              <div className={styles.priceSec}>
                <p className={`ffm fw600 fs18 c084571`}>Discounted price;</p>
                <p className={`ffm fw500 fs18 c084571`}>$0.12 per message</p>
              </div>
            </div>
            <div className={styles.sec}>
              <div className={`df aic ffm fs18 fw700 cfff ${styles.secHeader}`}>
                <div className={`df acc ${styles.imgWrpr}`}>
                  <img src={massEmail} alt="" />
                </div>{" "}
                Mass Emails
              </div>
              <p className={`ffm fw500 fs18 cfff ${styles.expText}`}>
                Coming Soon
              </p>
              {/* <div className={styles.priceSec}> */}
              {/* <p className={`ffm fw600 fs18 c084571`}>Discounted price;</p>
                <p className={`ffm fw500 fs18 c084571`}>¢23 per contact</p> */}
              {/* </div> */}
            </div>
          </div>
          {/* <div className={`${styles.line}`}>
            <div className={`df ${styles.header}`}>
              <div className={`df acc ${styles.icon}`}>
                <img src={selfPrint} alt="" />
              </div>
              <div>
                <p className={`ffm fw700 fs24 c084571`}>Self-print Labels</p>
                <p className={`ffm fw500 fs20 c084571`}>
                  We offer ready-to-print templates, with a wide choice of
                  fonts:
                </p>
              </div>
            </div>
            <div className={`bcD4E6FA ${styles.content}`}>
              <p className={`ffm fw600 fs20 c084571`}>Discounted price;</p>
              <p className={`ffm fw500 fs20 c084571`}>¢23 per contact</p>
            </div>
          </div>

          <div className={`${styles.line}`}>
            <div className={`df ${styles.header}`}>
              <div className={`df acc ${styles.icon}`}>
                <img src={genviteLabels} alt="" />
              </div>
              <div>
                <p className={`ffm fw700 fs24 c084571`}>Genvite Labels</p>
                <p className={`ffm fw500 fs20 c084571`}>
                  Printed in-house by our team, with choice of fonts available:
                </p>
              </div>
            </div>
            <div className={`bcD4E6FA ${styles.content}`}>
              <p className={`ffm fw600 fs20 c084571`}>
                Discounted price;{" "}
                <span className={`ffm fw500 fs20 c084571`}>
                  Large clear labels (14 per sheet) = ¢38
                </span>
              </p>
              <p className={`ffm fw600 fs20 c084571`}>
                {" "}
                Discounted price;{" "}
                <span className={`ffm fw500 fs20 c084571`}>
                  Regular white labels (30 per sheet) = ¢28
                </span>
              </p>
            </div>
          </div>

          <div className={`${styles.line}`}>
            <div className={`df ${styles.header}`}>
              <div className={`df acc ${styles.icon}`}>
                <img src={envelopePrint} alt="" />
              </div>
              <div>
                <p className={`ffm fw700 fs24 c084571`}>Print on Envelope</p>
                <p className={`ffm fw500 fs20 c084571`}>
                  We will print recipient's address on the envelope, matching
                  the font and style of the return address
                </p>
              </div>
            </div>
            <div className={`bcD4E6FA ${styles.content}`}>
              <p className={`ffm fw600 fs20 c084571`}>
                Discounted price;{" "}
                <span className={`ffm fw500 fs20 c084571`}>
                  ¢45 per envelope
                </span>
              </p>
              <p className={`ffm fw400 fs20 c084571`}>
                (complete stuffing & mailing, additional ¢27 + postage
              </p>
            </div>
          </div>

          <div className={`${styles.line}`}>
            <div className={`df ${styles.header}`}>
              <div className={`df acc ${styles.icon}`}>
                <img src={voiceCalls} alt="" />
              </div>
              <div>
                <p className={`ffm fw700 fs24 c084571`}>Voice Calls</p>
                <p className={`ffm fw500 fs20 c084571`}>
                  Invite family and loved ones with a phone call
                </p>
              </div>
            </div>
            <div className={`bcD4E6FA ${styles.content}`}>
              <p className={`ffm fw600 fs20 c084571`}>
                Discounted price;{" "}
                <span className={`ffm fw500 fs20 c084571`}>¢.12 per call*</span>
              </p>
              <p className={`ffm fw400 fs20 c084571`}>
                (*Call duration up to 2 minutes)
              </p>
            </div>
          </div>

          <div className={`${styles.line}`}>
            <div className={`df ${styles.header}`}>
              <div className={`df acc ${styles.icon}`}>
                <img src={textMassage} alt="" />
              </div>
              <div>
                <p className={`ffm fw700 fs24 c084571`}>Text Messages</p>
                <p className={`ffm fw500 fs20 c084571`}>
                  Send a text to their phone with your simcha invitation
                </p>
              </div>
            </div>
            <div className={`bcD4E6FA ${styles.content}`}>
              <p className={`ffm fw600 fs20 c084571`}>Discounted price;</p>
              <p className={`ffm fw500 fs20 c084571`}>¢12 per text message</p>
            </div>
          </div>*/}
        </div>
      </div>

      <div className={`bcFFF9F4 c084571 ${styles.needSomething}`}>
        <div className={`content-wrpr`}>
          <p className={`ffm fs28 fw400 tac`}>NEED SOMETHING YOU DON’T SEE?</p>
          <p className={`ffm fs28 fw400 tac ${styles.mb}`}>
            REACH OUT AND OUR TEAM WILL BE HAPPY TO ASSIST YOU!
          </p>
          <p className={`ffm fs32 fw600 tac`}>
            KICK OFF YOUR SIMCHA WITH PEACE OF MIND{" "}
          </p>

          <div className={`df aic`}>
            <a
              href="https://portal.genvite.com/auth/signup"
              rel="noopener noreferrer"
              className={`bc548ED1 ffm fw600 fs20 cfff`}
            >
              Get Started
            </a>
          </div>
        </div>
      </div>
      <div
        className={`ffm fs22 fw500 ttuc tac bc145F95 cfff ${styles.footerBar}`}
      >
        LET US HELP GET THE WORD OUT
      </div>
    </div>
  );
};

export default PricingLanding;
