import styles from "./Points.module.scss";

import customizable from "../../../assets/imgs/customizable.png";
import uptodate from "../../../assets/imgs/uptodate.png";
import mailorfull from "../../../assets/imgs/mailorfull.png";
import uploadcontacts from "../../../assets/imgs/uploadcontacts.png";

const Points = () => {
  return (
    <>
      <div className={`${styles.pointsSec}`}>
        <div className={`content-wrpr`}>
          <div className={styles.points}>
            <div className={`${styles.cell}`}>
              <div className={`df acc ${styles.iconWrpr}`}>
                <img src={customizable} alt="" />
              </div>
              <h5 className={`ffm fw700 fs20`}>Customizable</h5>
              <p className={`ffm fw500 fs14`}>
                Our automated lists are just the starting point. We make it easy
                to go through color-coded suggestions and click on the names you
                wish to accept or reject.
              </p>
            </div>
            <div className={`${styles.cell}`}>
              <div className={`df acc ${styles.iconWrpr}`}>
                <img src={uptodate} alt="" />
              </div>
              <h5 className={`ffm fw700 fs20`}>Up-to-date Information</h5>
              <p className={`ffm fw500 fs14`}>
                As a leading label and mailing service, we have the most
                up-to-date
              </p>
              <p className={`ffm fw500 fs14`}>
                addresses for each entry, cross-referenced with official USPS
                mailing information.
              </p>
            </div>
            <div className={`${styles.cell}`}>
              <div className={`df acc ${styles.iconWrpr}`}>
                <img src={mailorfull} alt="" />
              </div>
              <h5 className={`ffm fw700 fs20`}>Self-mail or Full Service</h5>
              <p className={`ffm fw500 fs14`}>
                We’re here to make things easy. With your lists in hand, you can
                proceed to get your invitations printed and mailed on your own
                or utilize our services to handle everything on your behalf.
              </p>
            </div>
            <div className={`${styles.cell}`}>
              <div className={`df acc ${styles.iconWrpr}`}>
                <img src={uploadcontacts} alt="" />
              </div>
              <h5 className={`ffm fw700 fs20`}>Upload Your Contacts</h5>
              <p className={`ffm fw500 fs14`}>
                For customers who already have compiled lists, we provide simple
                uploading options that will correct information as needed and
                offer relevant suggestions to help complete the list.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`bc145F95 tac ffm cfff fs22 fw500 ${styles.ftr}`}>
        LET US HELP GET THE WORD OUT
      </div>
    </>
  );
};

export default Points;
